import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/creators/creators-dashboard/creators-dashboard.module').then(m => m.CreatorsDashboardPageModule)
  },
  {
    path: 'ai/assistants',
    loadChildren: () => import('./pages/ai/ai-assistants/ai-assistants.module').then(m => m.AiAssistantsPageModule)
  },
  {
    path: 'ai/assistant',
    loadChildren: () => import('./pages/ai/ai-assistant/ai-assistant.module').then(m => m.AiAssistantPageModule)
  },
  {
    path: 'ai/coder',
    loadChildren: () => import('./pages/ai/ai-coder/ai-coder.module').then(m => m.AiCoderPageModule)
  },
  {
    path: 'ai/models',
    loadChildren: () => import('./pages/ai/ai-models/ai-models.module').then(m => m.AiModelsPageModule)
  },
  {
    path: 'ai/model',
    loadChildren: () => import('./pages/ai/ai-model/ai-model.module').then(m => m.AiModelPageModule)
  },
  {
    path: 'ai/search',
    loadChildren: () => import('./pages/ai/ai-search/ai-search.module').then(m => m.AiSearchPageModule)
  },
  {
    path: 'ai/store',
    loadChildren: () => import('./pages/ai/ai-store/ai-store.module').then(m => m.AiStorePageModule)
  },
  {
    path: 'ai/tasks',
    loadChildren: () => import('./pages/ai/ai-tasks/ai-tasks.module').then(m => m.AiTasksPageModule)
  },
  {
    path: 'ai/task/cron',
    loadChildren: () => import('./pages/ai/ai-task-cron-settings/ai-task-cron-settings.module').then(m => m.AiTaskCronSettingsPageModule)
  },
  {
    path: 'ai/task/execute/settings',
    loadChildren: () => import('./pages/ai/ai-task-execution-settings/ai-task-execution-settings.module').then(m => m.AiTaskExecutionSettingsPageModule)
  },
  {
    path: 'ai/tools',
    loadChildren: () => import('./pages/ai/ai-tools/ai-tools.module').then(m => m.AiToolsPageModule)
  },
  {
    path: 'ai/training',
    loadChildren: () => import('./pages/ai/ai-training/ai-training.module').then(m => m.AiTrainingPageModule)
  },
  {
    path: 'ai/training/knowledgebase',
    loadChildren: () => import('./pages/ai/ai-knowledgebase/ai-knowledgebase.module').then(m => m.AiKnowledgebasePageModule)
  },
  {
    path: 'ai/training/knowledgebase/:itemId',
    loadChildren: () => import('./pages/ai/ai-knowledgebase-entry/ai-knowledgebase-entry.module').then(m => m.AiKnowledgebaseEntryPageModule)
  },
  {
    path: 'ai/training/log',
    loadChildren: () => import('./pages/ai/ai-training/ai-training.module').then(m => m.AiTrainingPageModule)
  },
  {
    path: 'ai/text-to-audio',
    loadChildren: () => import('./pages/ai/ai-text-to-audio/ai-text-to-audio.module').then(m => m.AiTextToAudioPageModule)
  },
  {
    path: 'ai/text-to-speech',
    loadChildren: () => import('./pages/ai/ai-tts/ai-tts.module').then(m => m.AiTtsPageModule)
  },
  {
    path: 'ai/shortcode',
    loadChildren: () => import('./pages/ai/ai-shortcode/ai-shortcode.module').then(m => m.AiShortcodePageModule)
  },
  {
    path: 'ai/automation/:taskId',
    loadChildren: () => import('./pages/ai/ai-task/ai-task.module').then(m => m.AiQuickPageModule)
  },
  {
    path: 'ai/quick',
    loadChildren: () => import('./pages/ai/ai-task/ai-task.module').then(m => m.AiQuickPageModule)
  },
  {
    path: 'ai/ideas',
    loadChildren: () => import('./pages/ai/ai-ideas/ai-ideas.module').then(m => m.AiIdeasPageModule)
  },
  {
    path: 'ai/plans',
    loadChildren: () => import('./pages/ai/ai-plans/ai-plans.module').then(m => m.AiPlansPageModule)
  },
  {
    path: 'api/explorer',
    loadChildren: () => import('./pages/api/explorer/api-explorer.module').then(m => m.ApiExplorerPageModule)
  },
  {
    path: 'api/explorer/:integrationName',
    loadChildren: () => import('./pages/api/explorer/api-explorer.module').then(m => m.ApiExplorerPageModule)
  },
  {
    path: 'campaigns',
    loadChildren: () => import('./pages/ai/ai-plans/ai-plans.module').then(m => m.AiPlansPageModule)
  },
  {
    path: 'campaigns/:planId',
    loadChildren: () => import('./pages/ai/ai-planner/ai-planner.module').then(m => m.AiPlannerPageModule)
  },
  {
    path: 'campaigns/:planId/edit',
    loadChildren: () => import('./pages/ai/ai-planner/ai-planner.module').then(m => m.AiPlannerPageModule)
  },
  {
    path: 'campaigns/:planId/preview',
    loadChildren: () => import('./pages/ai/ai-planner/ai-planner.module').then(m => m.AiPlannerPageModule)
  },
  {
    path: 'campaigns/:planId/review',
    loadChildren: () => import('./pages/ai/ai-planner/ai-planner.module').then(m => m.AiPlannerPageModule)
  },
  {
    path: 'ai/planner',
    loadChildren: () => import('./pages/ai/ai-planner/ai-planner.module').then(m => m.AiPlannerPageModule)
  },
  {
    path: 'ai/url-lookup',
    loadChildren: () => import('./pages/ai/ai-url-lookup/ai-url-lookup.module').then(m => m.AiUrlLookupPageModule)
  },
  {
    path: 'links',
    loadChildren: () => import('./pages/crud/crud-list/crud-list.module').then(m => m.CrudListPageModule)
  },
  {
    path: 'link/lookup',
    loadChildren: () => import('./pages/ai/ai-url-lookup/ai-url-lookup.module').then(m => m.AiUrlLookupPageModule)
  },
  {
    path: 'link/:lookupIndent',
    loadChildren: () => import('./pages/ai/ai-url-lookup/ai-url-lookup.module').then(m => m.AiUrlLookupPageModule)
  },
  {
    path: 'ai/video-creator',
    loadChildren: () => import('./pages/ai/ai-video-creator/ai-video-creator.module').then(m => m.AiVideoCreatorPageModule)
  },
  {
    path: 'ai/video-creator/create',
    loadChildren: () => import('./pages/ai/ai-video-creator/ai-video-creator.module').then(m => m.AiVideoCreatorPageModule)
  },
  {
    path: 'ai/video-creator/list',
    loadChildren: () => import('./pages/crud/crud-list/crud-list.module').then(m => m.CrudListPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account/account.module').then(m => m.AccountPageModule)
  },
  {
    path: 'account/usage',
    loadChildren: () => import('./pages/account/usage/usage.module').then(m => m.UsagePageModule)
  },
  {
    path: 'abonnements',
    loadChildren: () => import('./pages/abonnements/abonnements/abonnements.module').then(m => m.AbonnementsPageModule)
  },
  {
    path: 'creators-dashboard',
    loadChildren: () => import('./pages/creators/creators-dashboard/creators-dashboard.module').then(m => m.CreatorsDashboardPageModule)
  },
  {
    path: 'data/export',
    loadChildren: () => import('./pages/data-exporter/data-exporter.module').then(m => m.DataExporterPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
  },
  {
    path: 'generic/item',
    loadChildren: () => import('./pages/generic/generic-item/generic-item.module').then(m => m.GenericItemPageModule)
  },
  {
    path: 'generic/item',
    loadChildren: () => import('./pages/generic/generic-list/generic-list.module').then(m => m.GenericListPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/account/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/account/logout/logout.module').then(m => m.LogoutPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/account/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: 'post',
    loadChildren: () => import('./pages/post/post/post.module').then(m => m.PostPageModule)
  },
  {
    path: 'detail',
    loadChildren: () => import('./pages/detail/detail.module').then(m => m.DetailPageModule)
  },
  {
    path: 'dynamic/:pageId',
    loadChildren: () => import('./pages/pages/dynamic/dynamic.module').then(m => m.DynamicPageModule)
  },
  {
    path: 'basket',
    loadChildren: () => import('./pages/shop/basket/basket.module').then(m => m.BasketPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/shop/checkout/checkout.module').then(m => m.CheckoutPageModule)
  },
  {
    path: 'configure-product',
    loadChildren: () => import('./pages/shop/configure-product/configure-product.module').then(m => m.ConfigureProductPageModule)
  },
  {
    path: 'coupon',
    loadChildren: () => import('./pages/coupons/coupon/coupon.module').then(m => m.CouponPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./pages/shop/payment/payment.module').then(m => m.PaymentPageModule)
  },
  {
    path: 'contacts',
    loadChildren: () => import('./pages/contacts/contacts.module').then(m => m.ContactsPageModule)
  },
  {
    path: 'customers-admin',
    loadChildren: () => import('./pages/customers/customers-admin/customers-admin.module').then(m => m.CustomersAdminPageModule)
  },
  {
    path: 'customer',
    loadChildren: () => import('./pages/customers/customer/customer.module').then(m => m.CustomerPageModule)
  },
  {
    path: 'stores-admin',
    loadChildren: () => import('./pages/stores/stores-admin/stores-admin.module').then(m => m.StoresAdminPageModule)
  },
  {
    path: 'store',
    loadChildren: () => import('./pages/stores/store/store.module').then(m => m.StorePageModule)
  },
  {
    path: 'regional-admin',
    loadChildren: () => import('./pages/regions/regional-admin/regional-admin.module').then(m => m.RegionalAdminPageModule)
  },
  {
    path: 'regional-create-source',
    loadChildren: () => import('./pages/regions/regional-create-source/regional-create-source.module').then(m => m.RegionalCreateSourcePageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/account/change-password/change-password.module').then(m => m.ChangePasswordPageModule)
  },
  {
    path: 'rate-post',
    loadChildren: () => import('./pages/ratings/rate-post/rate-post.module').then(m => m.RatePostPageModule)
  },
  {
    path: 'create-source',
    loadChildren: () => import('./pages/sources/create-source/create-source.module').then(m => m.CreateSourcePageModule)
  },
  {
    path: 'delete-account',
    loadChildren: () => import('./pages/account/delete-account/delete-account.module').then(m => m.DeleteAccountPageModule)
  },
  {
    path: 'privacy-intro',
    loadChildren: () => import('./pages/intro/privacy-intro/privacy-intro.module').then(m => m.PrivacyIntroPageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./pages/feedback/feedback.module').then(m => m.FeedbackPageModule)
  },
  {
    path: 'experiments',
    loadChildren: () => import('./pages/experiments/experiments.module').then(m => m.ExperimentsPageModule)
  },
  {
    path: 'appearance',
    loadChildren: () => import('./pages/appearance/appearance.module').then(m => m.AppearancePageModule)
  },
  {
    path: 'ads',
    loadChildren: () => import('./pages/ads/ads-admin/ads-admin.module').then(m => m.AdsAdminPageModule)
  },
  {
    path: 'ads/create',
    loadChildren: () => import('./pages/ads/create-ad/create-ad.module').then(m => m.CreateAdPageModule)
  },
  {
    path: 'telemetry',
    loadChildren: () => import('./pages/account/telemetry/telemetry.module').then(m => m.TelemetryPageModule)
  },
  {
    path: 'ratings-admin',
    loadChildren: () => import('./pages/ratings/ratings-admin/ratings-admin.module').then(m => m.RatingsAdminPageModule)
  },
  {
    path: 'view/:slug',
    loadChildren: () => import('./pages/view/view.module').then(m => m.ViewPageModule)
  },
  {
    path: 'card-options',
    loadChildren: () => import('./pages/card-options/card-options.module').then(m => m.CardOptionsPageModule)
  },
  {
    path: 'statistics',
    loadChildren: () => import('./pages/statistics/creator-statistics/creator-statistics.module').then(m => m.CreatorStatisticsPageModule)
  },
  {
    path: 'statistics/social-media',
    loadChildren: () => import('./pages/statistics/social-media-statistics/social-media-statistics.module').then(m => m.SocialMediaStatisticsPageModule)
  },
  {
    path: 'statistics/trends',
    loadChildren: () => import('./pages/statistics/trends-statistics/trends-statistics.module').then(m => m.TrendsStatisticsPageModule)
  },
  {
    path: 'translation',
    loadChildren: () => import('./pages/translations/translation/translation.module').then(m => m.TranslationPageModule)
  },
  {
    path: 'translations',
    loadChildren: () => import('./pages/translations/translations/translations.module').then(m => m.TranslationsPageModule)
  },
  {
    path: 'post-information',
    loadChildren: () => import('./pages/post/post-information/post-information.module').then(m => m.PostInformationPageModule)
  },
  {
    path: 'connect-account-with-siwa',
    loadChildren: () => import('./pages/connect/connect-account-with-siwa/connect-account-with-siwa.module').then(m => m.ConnectAccountWithSiwaPageModule)
  },
  {
    path: 'cpm-admin',
    loadChildren: () => import('./pages/cpm/cpm-admin/cpm-admin.module').then(m => m.CpmAdminPageModule)
  },
  {
    path: 'create-cpm-entry',
    loadChildren: () => import('./pages/cpm/create-cpm-entry/create-cpm-entry.module').then(m => m.CreateCpmEntryPageModule)
  },
  {
    path: 'create-cpm-main-entry',
    loadChildren: () => import('./pages/cpm/create-cpm-main-entry/create-cpm-main-entry.module').then(m => m.CreateCpmMainEntryPageModule)
  },
  {
    path: 'cpm-add-popup',
    loadChildren: () => import('./pages/cpm/cpm-add-popup/cpm-add-popup.module').then(m => m.CpmAddPopupPageModule)
  },
  {
    path: 'edit-page/:postId',
    loadChildren: () => import('./pages/pages/edit-page/edit-page.module').then(m => m.EditPagePageModule)
  },
  {
    path: 'edit-post/:postId',
    loadChildren: () => import('./pages/post/edit-post/edit-post.module').then(m => m.EditPostPageModule)
  },
  {
    path: 'post/new',
    loadChildren: () => import('./pages/post/edit-post/edit-post.module').then(m => m.EditPostPageModule)
  },
  {
    path: 'post/:postId',
    loadChildren: () => import('./pages/post/post/post.module').then(m => m.PostPageModule)
  },
  {
    path: 'edit-post-translations-modal',
    loadChildren: () => import('./pages/post/edit-post-translations-modal/edit-post-translations-modal.module').then(m => m.EditPostTranslationsModalPageModule)
  },
  {
    path: 'add-region',
    loadChildren: () => import('./pages/regions/add-region/add-region.module').then(m => m.AddRegionPageModule)
  },
  {
    path: 'blog-admin-employee-motivation',
    loadChildren: () => import('./pages/blog-admin/blog-admin-employee-motivation/blog-admin-employee-motivation.module').then(m => m.BlogAdminEmployeeMotivationPageModule)
  },
  {
    path: 'edit-source',
    loadChildren: () => import('./pages/sources/edit-source/edit-source.module').then(m => m.EditSourcePageModule)
  },
  {
    path: 'cookies',
    loadChildren: () => import('./pages/cookies/cookies.module').then(m => m.CookiesPageModule)
  },
  {
    path: 'edit-post-settings',
    loadChildren: () => import('./pages/post/edit-post-settings/edit-post-settings.module').then(m => m.EditPostSettingsPageModule)
  },
  {
    path: 'region-setup',
    loadChildren: () => import('./pages/regions/region-setup/region-setup.module').then(m => m.RegionSetupPageModule)
  },
  {
    path: 'choose-avatar',
    loadChildren: () => import('./pages/chooser/choose-avatar/choose-avatar.module').then(m => m.ChooseAvatarPageModule)
  },
  {
    path: 'edit-shop-source',
    loadChildren: () => import('./pages/shop/edit-shop-source/edit-shop-source.module').then(m => m.EditShopSourcePageModule)
  },
  {
    path: 'choose-cover',
    loadChildren: () => import('./pages/chooser/choose-cover/choose-cover.module').then(m => m.ChooseCoverPageModule)
  },
  {
    path: 'modal',
    loadChildren: () => import('./pages/modals/modal/modal.module').then(m => m.ModalPageModule)
  },
  {
    path: 'sharing-modal',
    loadChildren: () => import('./pages/modals/sharing-modal/sharing-modal.module').then(m => m.SharingModalPageModule)
  },
  {
    path: 'posts-admin',
    loadChildren: () => import('./pages/post/posts-admin/posts-admin.module').then(m => m.PostsAdminPageModule)
  },
  {
    path: 'hub',
    loadChildren: () => import('./pages/post/posts-admin/posts-admin.module').then(m => m.PostsAdminPageModule)
  },
  {
    path: 'hub/:segmentName',
    loadChildren: () => import('./pages/post/posts-admin/posts-admin.module').then(m => m.PostsAdminPageModule)
  },
  {
    path: 'web-uploader',
    loadChildren: () => import('./pages/web-uploader/web-uploader.module').then(m => m.WebUploaderPageModule)
  },
  {
    path: 'sources/blogs',
    loadChildren: () => import('./pages/creators/creator-blogs/creator-blogs.module').then(m => m.CreatorBlogsPageModule)
  },
  {
    path: 'sources/shops',
    loadChildren: () => import('./pages/creators/creator-shops/creator-shops.module').then(m => m.CreatorShopsPageModule)
  },
  {
    path: 'connect/shop',
    loadChildren: () => import('./pages/connect/connect-shop/connect-shop.module').then(m => m.ConnectShopPageModule)
  },
  {
    path: 'connect/blog',
    loadChildren: () => import('./pages/connect/connect-blog/connect-blog.module').then(m => m.ConnectBlogPageModule)
  },
  {
    path: 'target-groups',
    loadChildren: () => import('./pages/target_groups/target-groups/target-groups.module').then(m => m.TargetGroupsPageModule)
  },
  {
    path: 'target-groups/create',
    loadChildren: () => import('./pages/target_groups/create-target-group/create-target-group.module').then(m => m.CreateTargetGroupPageModule)
  },
  {
    path: 'choose-action-sheet',
    loadChildren: () => import('./pages/chooser/choose-action-sheet/choose-action-sheet.module').then(m => m.ChooseActionSheetPageModule)
  },
  {
    path: 'icon-picker',
    loadChildren: () => import('./pages/icon-picker/icon-picker.module').then(m => m.IconPickerPageModule)
  },
  {
    path: 'security',
    loadChildren: () => import('./pages/account/security/security.module').then(m => m.SecurityPageModule)
  },
  {
    path: 'change-email',
    loadChildren: () => import('./pages/account/change-email/change-email.module').then(m => m.ChangeEmailPageModule)
  },
  {
    path: 'inbox',
    loadChildren: () => import('./pages/inbox/inbox.module').then(m => m.InboxPageModule)
  },
  {
    path: 'messenger',
    loadChildren: () => import('./pages/chat/messenger/messenger.module').then(m => m.MessengerPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule)
  },
  {
    path: 'connect',
    loadChildren: () => import('./pages/connect/connect/connect.module').then(m => m.ConnectPageModule)
  },
  {
    path: 'creator-requests',
    loadChildren: () => import('./pages/creators/creator-requests/creator-requests.module').then(m => m.CreatorRequestsPageModule)
  },
  {
    path: 'social-media-images-preview',
    loadChildren: () => import('./pages/preview/social-media-images-preview/social-media-images-preview.module').then(m => m.SocialMediaImagesPreviewPageModule)
  },
  {
    path: 'edit-shop/:shopId',
    loadChildren: () => import('./pages/shop/edit-shop/edit-shop.module').then(m => m.EditShopPageModule)
  },
  {
    path: 'creators-dashboard',
    loadChildren: () => import('./pages/creators/creators-dashboard/creators-dashboard.module').then(m => m.CreatorsDashboardPageModule)
  },
  {
    path: 'shop/categories',
    loadChildren: () => import('./pages/shop/shop-categories/shop-categories.module').then(m => m.ShopCategoriesPageModule)
  },
  {
    path: 'shop/category/:categoryId',
    loadChildren: () => import('./pages/shop/shop-category/shop-category.module').then(m => m.ShopCategoryPageModule)
  },
  {
    path: 'shop/category/:categoryId/products',
    loadChildren: () => import('./pages/shop/shop-products/shop-products.module').then(m => m.ShopProductsPageModule)
  },
  {
    path: 'shop/products',
    loadChildren: () => import('./pages/shop/shop-products/shop-products.module').then(m => m.ShopProductsPageModule)
  },
  {
    path: 'shop/products/:productId',
    loadChildren: () => import('./pages/shop/shop-product/shop-product.module').then(m => m.ShopProductPageModule)
  },
  {
    path: 'newsletters',
    loadChildren: () => import('./pages/newsletters/newsletters/newsletters.module').then(m => m.NewslettersPageModule)
  },
  {
    path: 'newsletters/create',
    loadChildren: () => import('./pages/newsletters/newsletter/newsletter.module').then(m => m.NewsletterPageModule)
  },
  {
    path: 'newsletters/:uid',
    loadChildren: () => import('./pages/newsletters/newsletter/newsletter.module').then(m => m.NewsletterPageModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersPageModule)
  },
  {
    path: 'post-reader-appearance-popup',
    loadChildren: () => import('./pages/post/post-reader-appearance-popup/post-reader-appearance-popup.module').then(m => m.PostReaderAppearancePopupPageModule)
  },
  {
    path: 'creator/websites/edit/:websiteId',
    loadChildren: () => import('./pages/websites/edit-website/edit-website.module').then(m => m.EditWebsitePageModule)
  },
  {
    path: 'creator/websites',
    loadChildren: () => import('./pages/websites/creator-websites/creator-websites.module').then(m => m.CreatorWebsitesPageModule)
  },
  {
    path: 'creator/website/create',
    loadChildren: () => import('./pages/websites/create-website/create-website.module').then(m => m.CreateWebsitePageModule)
  },
  {
    path: 'preview-post',
    loadChildren: () => import('./pages/preview/preview-post/preview-post.module').then(m => m.PreviewPostPageModule)
  },
  {
    path: 'preview-ad',
    loadChildren: () => import('./pages/preview/preview-ad/preview-ad.module').then(m => m.PreviewAdPageModule)
  },
  {
    path: 'preview-social-sharing-image',
    loadChildren: () => import('./pages/preview/preview-social-sharing-image/preview-social-sharing-image.module').then(m => m.PreviewSocialSharingImagePageModule)
  },
  {
    path: 'preview-social-sharing-image-filters',
    loadChildren: () => import('./pages/preview/preview-social-sharing-image-filters/preview-social-sharing-image-filters.module').then(m => m.PreviewSocialSharingImageFiltersPageModule)
  },
  {
    path: 'posts-admin-settings',
    loadChildren: () => import('./pages/post/posts-admin-settings/posts-admin-settings.module').then(m => m.PostsAdminSettingsPageModule)
  },
  {
    path: 'marketplace/dashboard',
    loadChildren: () => import('./pages/marketplace/creators-marketplace/creators-marketplace.module').then(m => m.CreatorsMarketplacePageModule)
  },
  {
    path: 'marketplace/search',
    loadChildren: () => import('./pages/marketplace/creators-marketplace-search/creators-marketplace-search.module').then(m => m.CreatorsMarketplaceSearchPageModule)
  },
  {
    path: 'marketplace/messages',
    loadChildren: () => import('./pages/inbox/inbox.module').then(m => m.InboxPageModule)
  },
  {
    path: 'change-account',
    loadChildren: () => import('./pages/account/change-account/change-account.module').then(m => m.ChangeAccountPageModule)
  },
  {
    path: 'order-by-modal',
    loadChildren: () => import('./pages/modals/order-by-modal/order-by-modal.module').then(m => m.OrderByModalPageModule)
  },
  {
    path: 'confirm-modal',
    loadChildren: () => import('./pages/modals/confirm-modal/confirm-modal.module').then(m => m.ConfirmModalPageModule)
  },
  {
    path: 'whitelabel-apps',
    loadChildren: () => import('./pages/whitelabel-apps/whitelabel-apps/whitelabel-apps.module').then(m => m.WhitelabelAppsPageModule)
  },
  {
    path: 'apps',
    loadChildren: () => import('./pages/whitelabel-apps/whitelabel-apps/whitelabel-apps.module').then(m => m.WhitelabelAppsPageModule)
  },
  {
    path: 'marketplace-entry',
    loadChildren: () => import('./pages/marketplace/marketplace-entry/marketplace-entry.module').then(m => m.MarketplaceEntryPageModule)
  },
  {
    path: 'push-notifications',
    loadChildren: () => import('./pages/push-notifications/push-notifications/push-notifications.module').then(m => m.PushNotificationsPageModule)
  },
  {
    path: 'push-notification',
    loadChildren: () => import('./pages/push-notifications/push-notification/push-notification.module').then(m => m.PushNotificationPageModule)
  },
  {
    path: 'whitelabel-app',
    loadChildren: () => import('./pages/whitelabel-apps/whitelabel-app/whitelabel-app.module').then(m => m.WhitelabelAppPageModule)
  },
  {
    path: 'blog/categories',
    loadChildren: () => import('./pages/blog-admin/blog-categories/blog-categories.module').then(m => m.BlogCategoriesPageModule)
  },
  {
    path: 'blog/category/:categoryId',
    loadChildren: () => import('./pages/blog-admin/blog-category/blog-category.module').then(m => m.BlogCategoryPageModule)
  },
  {
    path: 'blog/authors',
    loadChildren: () => import('./pages/blog-admin/blog-authors/blog-authors.module').then(m => m.BlogAuthorsPageModule)
  },
  {
    path: 'blog/author/:authorId',
    loadChildren: () => import('./pages/blog-admin/blog-author/blog-author.module').then(m => m.BlogAuthorPageModule)
  },
  {
    path: 'analytics-admin',
    loadChildren: () => import('./pages/analytics/analytics-admin/analytics-admin.module').then(m => m.AnalyticsAdminPageModule)
  },
  {
    path: 'analytics-entry',
    loadChildren: () => import('./pages/analytics/analytics-entry/analytics-entry.module').then(m => m.AnalyticsEntryPageModule)
  },
  {
    path: 'feeds',
    loadChildren: () => import('./pages/feeds/feeds/feeds.module').then(m => m.FeedsPageModule)
  },
  {
    path: 'feed/:feedId',
    loadChildren: () => import('./pages/feeds/feed/feed.module').then(m => m.FeedPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/account/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'whitelabel-app-popup-device',
    loadChildren: () => import('./pages/whitelabel-apps/whitelabel-app-popup-device/whitelabel-app-popup-device.module').then(m => m.WhitelabelAppPopupDevicePageModule)
  },
  {
    path: 'monetization',
    loadChildren: () => import('./pages/monetization/monetization/monetization.module').then(m => m.MonetizationPageModule)
  },
  {
    path: 'groups',
    loadChildren: () => import('./pages/groups/groups/groups.module').then(m => m.GroupsPageModule)
  },
  {
    path: 'surveys',
    loadChildren: () => import('./pages/surveys/surveys/surveys.module').then(m => m.SurveysPageModule)
  },
  {
    path: 'survey/:surveyId',
    loadChildren: () => import('./pages/surveys/survey/survey.module').then(m => m.SurveyPageModule)
  },
  {
    path: 'group/:groupId',
    loadChildren: () => import('./pages/groups/group/group.module').then(m => m.GroupPageModule)
  },
  {
    path: 'user-group',
    loadChildren: () => import('./pages/user-groups/user-group/user-group.module').then(m => m.UserGroupPageModule)
  },
  {
    path: 'user-groups',
    loadChildren: () => import('./pages/user-groups/user-groups/user-groups.module').then(m => m.UserGroupsPageModule)
  },
  {
    path: 'monetization-extension',
    loadChildren: () => import('./pages/monetization/monetization-extension/monetization-extension.module').then(m => m.MonetizationExtensionPageModule)
  },
  {
    path: 'events-admin',
    loadChildren: () => import('./pages/events/events-admin/events-admin.module').then(m => m.EventsAdminPageModule)
  },
  {
    path: 'event/:eventId',
    loadChildren: () => import('./pages/events/event/event.module').then(m => m.EventPageModule)
  },
  {
    path: 'edit-website-component',
    loadChildren: () => import('./pages/websites/edit-website-component/edit-website-component.module').then(m => m.EditWebsiteComponentPageModule)
  },
  {
    path: 'edit-website-menu',
    loadChildren: () => import('./pages/websites/edit-website-menu/edit-website-menu.module').then(m => m.EditWebsiteMenuPageModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages/pages/pages.module').then(m => m.PagesPageModule)
  },
  {
    path: 'create-chat-modal',
    loadChildren: () => import('./pages/chat/create-chat-modal/create-chat-modal.module').then(m => m.CreateChatModalPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/account/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'reportings-admin',
    loadChildren: () => import('./pages/reportings/reportings-admin/reportings-admin.module').then(m => m.ReportingsAdminPageModule)
  },
  {
    path: 'reporting',
    loadChildren: () => import('./pages/reportings/reporting/reporting.module').then(m => m.ReportingPageModule)
  },
  {
    path: 'calendars',
    loadChildren: () => import('./pages/calendar/calendars/calendars.module').then(m => m.CalendarsPageModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('./pages/calendar/calendar/calendar.module').then(m => m.CalendarPageModule)
  },
  {
    path: 'crud/item/:serviceName/:tableName/create',
    loadChildren: () => import('./pages/crud/crud-item/crud-item.module').then(m => m.CrudItemPageModule)
  },
  {
    path: 'crud/item/:serviceName/:tableName/:itemId',
    loadChildren: () => import('./pages/crud/crud-item/crud-item.module').then(m => m.CrudItemPageModule)
  },
  {
    path: 'crud/list',
    loadChildren: () => import('./pages/crud/crud-list/crud-list.module').then(m => m.CrudListPageModule)
  },
  {
    path: 'crud/list/:serviceName',
    loadChildren: () => import('./pages/crud/crud-list/crud-list.module').then(m => m.CrudListPageModule)
  },
  {
    path: 'crud/list/:serviceName/:tableName',
    loadChildren: () => import('./pages/crud/crud-list/crud-list.module').then(m => m.CrudListPageModule)
  },
  {
    path: 'db/tables',
    loadChildren: () => import('./pages/db/db-tables/db-tables.module').then(m => m.DbTablesPageModule)
  },
  {
    path: 'db/table/:tableName',
    loadChildren: () => import('./pages/db/db-table/db-table.module').then(m => m.DbTablePageModule)
  },
  {
    path: 'db/table/:tableName/:itemId',
    loadChildren: () => import('./pages/db/db-table-item/db-table-item.module').then(m => m.DbTableItemPageModule)
  },
  {
    path: 'announcements/death',
    loadChildren: () => import('./pages/announcements/death/death-announcements/death-announcements.module').then(m => m.DeathAnnouncementsPageModule)
  },
  {
    path: 'announcement/death/:itemId',
    loadChildren: () => import('./pages/announcements/death/death-announcement/death-announcement.module').then(m => m.DeathAnnouncementPageModule)
  },
  {
    path: 'announcements/real-estates',
    loadChildren: () => import('./pages/announcements/real-estates/real-estates/real-estates.module').then(m => m.RealEstatesPageModule)
  },
  {
    path: 'announcements/real-estates/:itemId',
    loadChildren: () => import('./pages/announcements/real-estates/real-estate/real-estate.module').then(m => m.RealEstatePageModule)
  },
  {
    path: 'coupons-admin',
    loadChildren: () => import('./pages/coupons/coupons-admin/coupons-admin.module').then(m => m.CouponsAdminPageModule)
  },
  {
    path: 'getgenius/dani',
    loadChildren: () => import('./pages/getgenius/dani/dani.module').then(m => m.DaniPageModule)
  },
  {
    path: 'dani/chat',
    loadChildren: () => import('./pages/getgenius/dani/dani.module').then(m => m.DaniPageModule)
  },
  {
    path: 'getgenius/wallet',
    loadChildren: () => import('./pages/getgenius/wallet/getgenius-wallet.module').then(m => m.GetgeniusWalletPageModule)
  },
  {
    path: 'jobs',
    loadChildren: () => import('./pages/jobs/jobs/jobs.module').then(m => m.JobsPageModule)
  },
  {
    path: 'job/:jobId',
    loadChildren: () => import('./pages/jobs/job/job.module').then(m => m.JobPageModule)
  },
  {
    path: 'job-titles',
    loadChildren: () => import('./pages/jobs/job-titles/job-titles.module').then(m => m.JobTitlesPageModule)
  },
  {
    path: 'job-title/:jobTitleId',
    loadChildren: () => import('./pages/jobs/job-title/job-title.module').then(m => m.JobTitlePageModule)
  },
  {
    path: 'quizzes',
    loadChildren: () => import('./pages/quizzes/quizzes/quizzes.module').then(m => m.QuizzesPageModule)
  },
  {
    path: 'quiz/:quizId',
    loadChildren: () => import('./pages/quizzes/quiz/quiz.module').then(m => m.QuizPageModule)
  },
  {
    path: 'company/:companyId',
    loadChildren: () => import('./pages/companies/company/company.module').then(m => m.CompanyPageModule)
  },
  {
    path: 'companies',
    loadChildren: () => import('./pages/companies/companies/companies.module').then(m => m.CompaniesPageModule)
  },
  {
    path: 'integrations',
    loadChildren: () => import('./pages/integrations/integrations/integrations.module').then(m => m.IntegrationsPageModule)
  },
  {
    path: 'integrations/comments',
    loadChildren: () => import('./pages/integrations/integration/generic/comments/comments.module').then(m => m.CommentsPageModule)
  },

  /*
  Generic crud handler for log:
  {
    path: 'integrations/log',
    loadChildren: () => import('./pages/crud/crud-list/crud-list.module').then(m => m.CrudListPageModule)
  },

  New log-entries handler:
  */
  {
    path: 'integrations/log',
    loadChildren: () => import('./pages/integrations/integration/generic/log-entries/log-entries.module').then(m => m.GenericLogEntriesPageModule)
  },

  {
    path: 'integration/settings/:integrationName',
    loadChildren: () => import('./pages/integrations/integration-settings/integration-settings.module').then(m => m.IntegrationSettingsPageModule)
  },
  {
    path: 'integrations/integration/elevenlabs/tts/create',
    loadChildren: () => import('./pages/integrations/integration/elevenlabs/create-tts/elevenlabs-create-tts.module').then(m => m.ElevenlabsCreateTtsPageModule)
  },
  {
    path: 'integrations/integration/heygen/video/edit',
    loadChildren: () => import('./pages/integrations/integration/heygen/edit-video/heygen-edit-video.module').then(m => m.HeygenEditVideoPageModule)
  },
  {
    path: 'integrations/integration/mail/blacklist',
    loadChildren: () => import('./pages/integrations/integration/mail/mail-crawler-blacklist/mail-crawler-blacklist.module').then(m => m.MailCrawlerBlacklistPageModule)
  },
  {
    path: 'integrations/integration/mail/crawler',
    loadChildren: () => import('./pages/integrations/integration/mail/mail-crawler/mail-crawler.module').then(m => m.MailCrawlerPageModule)
  },
  {
    path: 'integrations/integration/mail/crawlers',
    loadChildren: () => import('./pages/integrations/integration/mail/mail-crawlers/mail-crawlers.module').then(m => m.MailCrawlersPageModule)
  },
  {
    path: 'integrations/integration/mail/inbox',
    loadChildren: () => import('./pages/integrations/integration/mail/mail-inbox/mail-inbox.module').then(m => m.MailInboxPageModule)
  },
  {
    path: 'integrations/integration/mail/rule',
    loadChildren: () => import('./pages/integrations/integration/mail/mail-rule/mail-rule.module').then(m => m.MailRulePageModule)
  },
  {
    path: 'integrations/integration/mail/settings',
    loadChildren: () => import('./pages/integrations/integration/mail/mail-crawler-settings/mail-crawler-settings.module').then(m => m.MailCrawlerSettingsPageModule)
  },
  {
    path: 'integrations/integration/wordpress',
    loadChildren: () => import('./pages/integrations/integration/wordpress/wordpress.module').then(m => m.WordpressPageModule)
  },
  {
    path: 'integrations/integration/meta/groups',
    loadChildren: () => import('./pages/integrations/integration/meta/meta-groups/meta-groups.module').then(m => m.MetaGroupsPageModule)
  },
  {
    path: 'integrations/integration/meta/group',
    loadChildren: () => import('./pages/integrations/integration/meta/meta-group/meta-group.module').then(m => m.MetaGroupPageModule)
  },
  {
    path: 'integrations/integration/google/youtube', // @deprecated
    loadChildren: () => import('./pages/integrations/integration/generic/log-entries/log-entries.module').then(m => m.GenericLogEntriesPageModule)
  },
  {
    path: 'integrations/integration/telegram/bots', // @deprecated
    loadChildren: () => import('./pages/integrations/integration/generic/connections/generic-connections.module').then(m => m.GenericConnectionsPageModule)
  },
  {
    path: 'integrations/integration/:integrationName',
    loadChildren: () => import('./pages/integrations/integration/generic/log-entries/log-entries.module').then(m => m.GenericLogEntriesPageModule)
  },
  {
    path: 'integrations/integration/:integrationName/comments',
    loadChildren: () => import('./pages/integrations/integration/generic/comments/comments.module').then(m => m.CommentsPageModule)
  },
  {
    path: 'integrations/integration/:integrationName/connection',
    loadChildren: () => import('./pages/integrations/integration/generic/connection/generic-connection.module').then(m => m.GenericConnectionPageModule)
  },
  {
    path: 'integrations/integration/:integrationName/connections',
    loadChildren: () => import('./pages/integrations/integration/generic/connections/generic-connections.module').then(m => m.GenericConnectionsPageModule)
  },
  {
    path: 'integrations/integration/:integrationName/log',
    loadChildren: () => import('./pages/integrations/integration/generic/log-entries/log-entries.module').then(m => m.GenericLogEntriesPageModule)
  },
  {
    path: 'teams',
    loadChildren: () => import('./pages/teams/teams/teams.module').then(m => m.TeamsPageModule)
  },
  {
    path: 'team/:teamId',
    loadChildren: () => import('./pages/teams/team/team.module').then(m => m.TeamPageModule)
  },
  {
    path: 'audio/library',
    loadChildren: () => import('./pages/media/audio/library/audio-library.module').then(m => m.AudioLibraryPageModule)
  },
  {
    path: 'media/create',
    loadChildren: () => import('./pages/media/media/create-media/create-media.module').then(m => m.CreateMediaPageModule)
  },
  {
    path: 'media/folder/settings',
    loadChildren: () => import('./pages/media/media/media-folder-settings/media-folder-settings.module').then(m => m.MediaFolderSettingsPageModule)
  },
  {
    path: 'media/library',
    loadChildren: () => import('./pages/media/media/library/media-library.module').then(m => m.MediaLibraryPageModule)
  },
  {
    path: 'media/library/folder/:folderId',
    loadChildren: () => import('./pages/media/media/library/media-library.module').then(m => m.MediaLibraryPageModule)
  },
  {
    path: 'media/queue',
    loadChildren: () => import('./pages/media/media/media-queue/media-queue.module').then(m => m.MediaQueuePageModule)
  },
  {
    path: 'media/store/templates',
    loadChildren: () => import('./pages/media/templates/template-store/template-store.module').then(m => m.TemplateStorePageModule)
  },
  {
    path: 'media/templates',
    loadChildren: () => import('./pages/media/templates/templates/media-templates.module').then(m => m.MediaTemplatesPageModule)
  },
  {
    path: 'media/templates/editor/create',
    loadChildren: () => import('./pages/media/templates/template-editor/template-editor.module').then(m => m.TemplateEditorPageModule)
  },
  {
    path: 'media/templates/editor/:uid',
    loadChildren: () => import('./pages/media/templates/template-editor/template-editor.module').then(m => m.TemplateEditorPageModule)
  },
  {
    path: 'media/templates/preview/:templateId',
    loadChildren: () => import('./pages/media/templates/template-preview/template-preview.module').then(m => m.TemplatePreviewPageModule)
  },
  {
    path: 'media/queue/preview/:queueItemId',
    loadChildren: () => import('./pages/media/templates/template-preview/template-preview.module').then(m => m.TemplatePreviewPageModule)
  },
  {
    path: 'media/:mediaId',
    loadChildren: () => import('./pages/media/media/view-media/view-media.module').then(m => m.ViewMediaPageModule)
  },
  {
    path: 'sharing/queue',
    loadChildren: () => import('./pages/sharing/sharing-queue/sharing-queue.module').then(m => m.SharingQueuePageModule)
  },
  {
    path: 'sharing/log',
    loadChildren: () => import('./pages/sharing/sharing-log/sharing-log.module').then(m => m.SharingLogPageModule)
  },
  {
    path: 'project/:projectId',
    loadChildren: () => import('./pages/projects/project/project.module').then(m => m.ProjectPageModule)
  },
  {
    path: 'projects',
    loadChildren: () => import('./pages/projects/projects/projects.module').then(m => m.ProjectsPageModule)
  },
  {
    path: 'setup',
    loadChildren: () => import('./pages/getgenius/getgenius-setup/getgenius-setup.module').then(m => m.GetgeniusSetupPageModule)
  },
  {
    path: 'countries',
    loadChildren: () => import('./pages/regions/countries/countries.module').then(m => m.CountriesPageModule)
  },
  {
    path: 'country/:countryId',
    loadChildren: () => import('./pages/regions/country/country.module').then(m => m.CountryPageModule)
  },
  {
    path: 'city/:cityId',
    loadChildren: () => import('./pages/regions/city/city.module').then(m => m.CityPageModule)
  },
  {
    path: 'cities',
    loadChildren: () => import('./pages/regions/cities/cities.module').then(m => m.CitiesPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }